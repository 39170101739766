/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useContext } from 'react';

import Switch from '@mui/material/Switch';
import { Tooltip } from '../components/tooltip/Tooltip';
import capitalize from 'lodash/capitalize';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { palette } from '../themes/palette';
import { createUseStyles } from 'react-jss';
import { alpha } from '@mui/material/styles';
import clsx from 'clsx';
import { UIThemeContext, useThemeMode } from '../app/UIThemeContextProvider';

const useStyles = createUseStyles({
    lightModeTrack: {
        background: alpha(palette.warning, 0.4),
    },
    darkModeTrack: {
        background: alpha(palette.opaqueLightGrey, 0.2),
    },
    lightModeIcon: {
        color: palette.warning,
        transform: 'translate(-2px, -2px)',
    },
    darkModeIcon: {
        color: palette.nebulaNavy100,
        transform: 'translateY(-3px)',
    },
    switchBase: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
});

export const ThemeSwitchButton = () => {
    const themeContext = useContext(UIThemeContext);
    const themeMode = useThemeMode();
    const oppositeTheme = themeMode === 'light' ? 'dark' : 'light';
    const classes = useStyles();

    return (
        <Tooltip
            title={`${capitalize(
                themeMode
            )} mode turned on. Toggle to enable ${oppositeTheme} mode.`}
            delayToolip={400}>
            <span>
                <Switch
                    onChange={themeContext.toggleUITheme}
                    checked={themeMode === 'dark'}
                    icon={<LightModeIcon className={classes.lightModeIcon} />}
                    checkedIcon={<DarkModeIcon className={classes.darkModeIcon} />}
                    color="default"
                    classes={{
                        track: clsx(classes.lightModeTrack, {
                            [classes.darkModeTrack]: themeMode === 'dark',
                        }),
                        switchBase: classes.switchBase,
                    }}
                    disableRipple
                />
            </span>
        </Tooltip>
    );
};
