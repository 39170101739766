/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { NoItemsBox } from '../../../components/error/NoItemsBox';
import { generateTabName, persistActiveTabId } from '@starburstdata/query-editor';
import { useHistory } from 'react-router-dom';
import { ErrorDialog } from '../../../components/error/ErrorDialog';
import { TableActionBar } from '../../../components/table/TableActionBar';
import { LoadingButton } from '../../../components/LoadingButton';
import { useTableFilter } from '../../../components/table/useTableFilter';
import {
    createWorksheet,
    deleteWorksheet,
    UpdatedWorksheet,
    Worksheet,
} from '../../../api/savedQueriesApi';
import { ActionMenuItem, TableActionMenu } from '../../../components/table/TableActionMenu';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import { SavedQueryTable } from './SavedQueryTable';
import SavedQueryEditDialog from './SavedQueryEditDialog';
import { DeleteDialog } from '../../../components/dialog/DeleteDialog';
import { useContextMenuStyles } from '../../../components/ContextMenu/useContextMenuStyles';
import { DataLoader } from '../../../components/table/DataLoader';
import Box from '@mui/material/Box';
import { NoFilteredItemsBanner } from '../../../components/table/NoFilteredItemsBanner';
import { createUseStyles } from 'react-jss';
import { isReadOnlyWorksheet, isWorksheetOwner } from './WorksheetUtils';

interface SavedWorksheetsList {
    worksheets: Worksheet[] | null | undefined;
    worksheetsError: string | undefined;
    worksheetsLoading: boolean;
    reloadWorksheets: () => void;
    showSharedWithColumn: boolean;
    showShareButton: boolean;
    noItemsMessage: string;
}

const useStyles = createUseStyles({
    pageContent: {
        height: 'calc(100% - 52px)',
        display: 'flex',
        flexDirection: 'column',
    },
});
export const SavedWorksheetsList: React.FunctionComponent<SavedWorksheetsList> = ({
    worksheets,
    worksheetsError,
    worksheetsLoading,
    reloadWorksheets,
    showSharedWithColumn,
    showShareButton,
    noItemsMessage,
}) => {
    const history = useHistory();
    const classes = useContextMenuStyles();
    const localStyles = useStyles();
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [createPending, setCreatePending] = useState(false);
    const abortController = useRef<AbortController | undefined>(undefined);
    useEffect(() => {
        return () => {
            abortController.current?.abort();
        };
    }, []);
    const createNewQuery = useCallback(async () => {
        setCreatePending(true);
        try {
            const localAbortController = new AbortController();
            const { id } = await createWorksheet(
                {
                    name: generateTabName(),
                    tabNumber: new Date().getTime(),
                },
                localAbortController
            );
            abortController.current = localAbortController;
            persistActiveTabId(id);
            setCreatePending(false);
            history.push('/ide');
        } catch (e) {
            setOpenErrorDialog(true);
        }
    }, [history, abortController]);

    const { filteredItems, filter, setFilter } = useTableFilter<Worksheet>(
        worksheets,
        (worksheet: Worksheet) => [worksheet.name]
    );
    const [queryToEdit, setQueryToEdit] = useState<UpdatedWorksheet | null>(null);
    const [deleteWorksheetDialogOpen, setDeleteWorksheetDialogOpen] = useState(false);
    const [deleteWorksheetRow, setDeleteWorksheetRow] = useState<Worksheet>();
    const handleDeleteWorksheet = useCallback(async () => {
        if (deleteWorksheetRow) {
            await deleteWorksheet(deleteWorksheetRow.id);
            setDeleteWorksheetDialogOpen(false);
            reloadWorksheets();
        }
    }, [deleteWorksheetRow, reloadWorksheets]);

    const actionMenuItems: ActionMenuItem<{ row: Worksheet }>[] = useMemo(
        () => [
            {
                Icon: Edit,
                text: 'Change name',
                styles: classes.menuItemEnabled,
                disabled: ({ row }) => isReadOnlyWorksheet(row),
                tooltipText: ({ row }) =>
                    isReadOnlyWorksheet(row) ? 'This worksheet is read-only' : '',
                onClick: ({ row: { id, name, queryText } }) =>
                    setQueryToEdit({
                        id,
                        name,
                        queryText,
                    }),
            },
            {
                Icon: Delete,
                text: 'Delete',
                styles: classes.menuItemEnabledDangerous,
                disabled: ({ row }) => !isWorksheetOwner(row),
                tooltipText: ({ row }) =>
                    !isWorksheetOwner(row) ? 'You are not owner of this query' : '',
                onClick: ({ row }) => {
                    setDeleteWorksheetRow(row);
                    setDeleteWorksheetDialogOpen(true);
                },
            },
        ],
        [classes, isWorksheetOwner]
    );

    return (
        <>
            <ErrorDialog
                text={'Could not create a new query. Try again later.'}
                openErrorDialog={openErrorDialog}
                setOpenErrorDialog={setOpenErrorDialog}
            />
            {queryToEdit && (
                <SavedQueryEditDialog
                    worksheet={queryToEdit}
                    onClose={() => {
                        setQueryToEdit(null);
                    }}
                    reloadWorksheets={reloadWorksheets}
                />
            )}
            {deleteWorksheetRow && (
                <DeleteDialog
                    onConfirm={handleDeleteWorksheet}
                    isOpen={deleteWorksheetDialogOpen}
                    close={() => setDeleteWorksheetDialogOpen(false)}
                    entityType="worksheet"
                    entityHumanName={deleteWorksheetRow.name}
                    hideTextConfirmationInput
                />
            )}
            <DataLoader items={filteredItems} loading={worksheetsLoading} error={worksheetsError}>
                {(filteredWorksheets) => (
                    <Box className={localStyles.pageContent}>
                        {worksheets && worksheets.length ? (
                            <TableActionBar
                                action={
                                    <LoadingButton
                                        loading={createPending}
                                        variant="contained"
                                        color="primary"
                                        onClick={createNewQuery}
                                        style={{ marginTop: '0.5rem' }}>
                                        Create new query
                                    </LoadingButton>
                                }
                                itemsCount={filteredWorksheets.length}
                                getItemsStr={(worksheetsCount: number) =>
                                    worksheetsCount === 1 ? 'query' : 'queries'
                                }
                                filter={filter}
                                setFilter={setFilter}
                            />
                        ) : null}
                        {filteredWorksheets.length ? (
                            <TableActionMenu<Worksheet> items={actionMenuItems}>
                                <SavedQueryTable
                                    worksheets={filteredWorksheets}
                                    filter={filter}
                                    showSharedWithColumn={showSharedWithColumn}
                                    showShareButton={showShareButton}
                                    reloadWorksheets={reloadWorksheets}
                                />
                            </TableActionMenu>
                        ) : worksheets && worksheets.length ? (
                            <NoFilteredItemsBanner entityName="queries" searchPhrase={filter} />
                        ) : (
                            <NoItemsBox
                                title={noItemsMessage}
                                canCreateNewItem={true}
                                onCreateNewItem={createNewQuery}
                                newItemButtonLabel="Create new query"
                            />
                        )}
                    </Box>
                )}
            </DataLoader>
        </>
    );
};
