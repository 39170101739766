/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { alpha, createTheme, darken } from '@mui/material/styles';
import { palette } from './palette';
import { shadows } from './shadows';
import { CustomThemeConfig } from '../api/themeApi';

export const materialUiThemeCustom = (customThemeConfig: CustomThemeConfig) => {
    return createTheme({
        typography: {
            fontFamily: `${customThemeConfig.font}, sans-serif !important`,
            fontWeightRegular: 400,
            h3: {
                fontFamily: `${customThemeConfig.font}, sans-serif`,
                fontSize: '32px',
                fontWeight: 600,
                lineHeight: '48px',
                letterSpacing: '1px',
                color: palette.nebulaNavy,
                paddingTop: '2rem',
                paddingBottom: '1.5rem',
            },
            h4: {
                fontFamily: `${customThemeConfig.font}, sans-serif`,
                fontSize: '20px',
                fontWeight: 700,
                letterSpacing: '0.15px',
                lineHeight: '30px',
                marginTop: '8px',
                marginLeft: '48px',
                marginBottom: '16px',
                color: customThemeConfig.main,
            },
            h5: {
                fontFamily: `${customThemeConfig.font}, sans-serif`,
                fontSize: '18px',
                fontWeight: 600,
                marginBottom: '16px',
                letterSpacing: '0.15px',
                lineHeight: '27px',
                color: palette.nebulaNavy,
            },
            subtitle2: {
                fontWeight: 600,
                lineHeight: '24px',
                letterSpacing: '0.098px',
            },
            body1: {
                color: palette.black,
            },
            body2: {
                lineHeight: '24px',
                letterSpacing: '0.14px',
                fontSize: '14px',
            },
            heading: {
                fontFamily: customThemeConfig.font,
            },
        },
        palette: {
            mode: 'light',
            background: {
                default: palette.white,
            },
            primary: {
                dark: customThemeConfig.main,
                main: customThemeConfig.main,
            },
            secondary: {
                main: customThemeConfig.main,
            },
            link: {
                main: customThemeConfig.link,
            },
            teal: {
                main: palette.teal,
            },
            purple: {
                main: palette.purple,
            },
            fusionFuchsia: {
                main: palette.fusionFuchsia050,
                dark: palette.fusionFuchsia100,
                light: palette.fusionFuchsia025,
            },
            lavender: {
                main: palette.lavender,
            },
            green: {
                main: palette.green,
            },
            orange: {
                main: palette.orange,
            },
            nightBlue: {
                main: palette.nightBlue,
                dark: palette.nightBlueDark,
                light: palette.nightBlueLight,
            },
            customBlue: {
                main: palette.customBlue,
            },
            black: {
                main: customThemeConfig.main,
            },
            lightGrey: {
                main: palette.opaqueLightGrey,
                light: palette.codeHighlightGrey,
            },
            nebulaNavy: {
                main: palette.nebulaNavy,
                dark: customThemeConfig.main,
                light: palette.nebulaNavy500,
            },
            info: {
                main: palette.link,
            },
            success: {
                main: palette.success,
                dark: palette.successDark,
                light: palette.successLight,
            },
            warning: {
                main: palette.warning,
                dark: palette.warningDark,
                light: palette.warningLight,
            },
            hilite: {
                main: palette.hilite,
                dark: palette.hiliteDark,
                light: palette.hiliteLight,
            },
            error: {
                main: palette.error,
                dark: palette.errorDark,
                light: palette.errorLight,
            },
            bgLevel: {
                main: palette.bgLevel2,
                dark: palette.bgLevel4,
                light: palette.bgLevel3,
            },
            sqlHighlight: {
                main: palette.sqlHighlightBackground,
            },
            scrollbar: {
                main: palette.scrollbar,
            },
            inActiveText: {
                main: customThemeConfig.inActiveText,
            },
            activeText: {
                main: customThemeConfig.activeText,
            },
            tooltip: {
                main: customThemeConfig.tooltip,
            },
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 800,
                md: 1100,
                lg: 1400,
                xl: 1536,
            },
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        fontWeight: 'bold',
                        textTransform: 'none',
                        justifyContent: 'normal',
                        whiteSpace: 'nowrap',
                        boxShadow: 'none',
                    },
                    containedPrimary: {
                        '&:hover': {
                            backgroundColor: darken(customThemeConfig.main, 0.15),
                        },
                        '&.Mui-disabled': {
                            color: palette.nebulaNavy200,
                            backgroundColor: palette.nebulaNavy50,
                        },
                    },
                    outlined: {
                        color: customThemeConfig.main,
                        borderColor: customThemeConfig.main,
                        '&:hover': {
                            backgroundColor: alpha(customThemeConfig.main, 0.1),
                            borderColor: customThemeConfig.main,
                        },
                        '&.Mui-disabled': {
                            color: palette.nebulaNavy100,
                            borderColor: palette.nebulaNavy100,
                        },
                    },
                    textSecondary: {
                        color: customThemeConfig.error,
                    },
                },
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        fontSize: '14px',
                        '&.Mui-error': {
                            color: customThemeConfig.error,
                        },
                    },
                },
            },
            MuiFormControlLabel: {
                styleOverrides: {
                    label: {
                        fontSize: '14px',
                    },
                },
            },
            MuiFormControl: {
                defaultProps: {
                    size: 'small',
                },
            },
            MuiFormHelperText: {
                styleOverrides: {
                    root: {
                        letterSpacing: '0.15px',
                        '&.Mui-error': {
                            color: customThemeConfig.error,
                            marginLeft: 0,
                        },
                    },
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        fontSize: '16px',
                    },
                },
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        '&.Mui-focused': {
                            color: palette.nebulaNavy500,
                        },
                        '&.Mui-error': {
                            color: customThemeConfig.error,
                        },
                    },
                },
            },
            MuiInputAdornment: {
                styleOverrides: {
                    positionEnd: {
                        marginRight: '1rem',
                        color: customThemeConfig.error,
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        '& fieldset': {
                            border: `1px solid ${palette.nebulaNavy100}`,
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: `${customThemeConfig.main} !important`,
                        },
                        '&.Mui-error fieldset': {
                            borderColor: `${customThemeConfig.error}!important`,
                        },
                        paddingRight: 0,
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        '&.MuiAlert-standardError': {
                            background: palette.errorLight,
                            border: `1px solid ${palette.errorDark}`,
                            boxSizing: 'border-box',
                            borderRadius: '4px',
                            color: palette.errorDark,
                            fontSize: '14px',
                            lineHeight: '24px',
                            '.MuiAlert-icon': {
                                color: palette.errorDark,
                                marginTop: '0.125rem',
                            },
                        },
                        '&.MuiAlert-standardSuccess': {
                            background: palette.successLight,
                            border: `1px solid ${palette.successDark}`,
                            boxSizing: 'border-box',
                            borderRadius: '4px',
                            color: palette.successDark,
                            fontSize: '14px',
                            lineHeight: '24px',
                            '.MuiAlert-icon': {
                                color: palette.successDark,
                                marginTop: '0.125rem',
                            },
                        },
                        '&.MuiAlert-standardInfo': {
                            background: palette.infoLight,
                            border: `1px solid ${palette.infoDark}`,
                            boxSizing: 'border-box',
                            borderRadius: '4px',
                            color: palette.infoDark,
                            fontSize: '14px',
                            lineHeight: '24px',
                            '.MuiAlert-icon': {
                                color: palette.infoDark,
                                marginTop: '0.125rem',
                            },
                        },
                        '&.MuiAlert-standardWarning': {
                            background: palette.warningLight,
                            border: `1px solid ${palette.warningDark}`,
                            boxSizing: 'border-box',
                            borderRadius: '4px',
                            color: palette.warningDark,
                            lineHeight: '24px',
                            fontSize: '14px',
                            '.MuiAlert-icon': {
                                color: palette.warningDark,
                                marginTop: '0.125rem',
                            },
                        },
                    },
                },
            },
            MuiCard: {
                styleOverrides: {
                    root: {
                        boxShadow: shadows[1],
                    },
                },
            },
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        backgroundColor: customThemeConfig.tooltip,
                        fontWeight: 400,
                        fontSize: '0.875rem',
                        color: palette.nebulaNavy,
                        lineHeight: '20px',
                        letterSpacing: '0.252px',
                        borderRadius: '0.25rem',
                        padding: '0.75rem 1rem',
                    },
                    arrow: {
                        color: customThemeConfig.tooltip,
                    },
                },
            },
            MuiTextField: {
                styleOverrides: {
                    root: {
                        backgroundColor: palette.white,
                    },
                },
            },
            MuiBackdrop: {
                styleOverrides: {
                    root: {
                        backgroundColor: palette.black12,
                    },
                },
            },
            MuiSwitch: {
                styleOverrides: {
                    switchBase: {
                        '&.Mui-checked': {
                            '&$disabled': {
                                color: palette.fusionFuchsia100,
                            },
                            '&$disabled + $track': {
                                opacity: '0.5',
                                backgroundColor: palette.fusionFuchsia050,
                            },
                        },
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        borderBottom: `1px solid ${palette.nebulaNavy50}`,
                    },
                },
            },
            MuiLink: {
                defaultProps: {
                    underline: 'none',
                    color: customThemeConfig.link,
                },
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        '&:hover': {
                            backgroundColor: `${customThemeConfig.menuItemHover} !important`,
                            color: palette.nebulaNavy,
                        },
                        '&.Mui-selected': {
                            backgroundColor: `${alpha(customThemeConfig.main, 0.1)} !important`,
                            color: palette.nebulaNavy,
                        },
                    },
                },
            },
            MuiAutocomplete: {
                styleOverrides: {
                    listbox: {
                        boxSizing: 'border-box',
                        '& ul': {
                            padding: 0,
                            margin: 0,
                        },
                    },
                    option: {
                        '&.Mui-focused': {
                            backgroundColor: `${palette.nebulaNavy15} !important`,
                            color: palette.nebulaNavy,
                        },
                        '&[aria-selected="true"]': {
                            backgroundColor: `${palette.nebulaNavy50} !important`,
                        },
                    },
                },
            },
            MuiCheckbox: {
                styleOverrides: {
                    root: {
                        color: palette.nebulaNavy300,
                        backgroundColor: palette.white,
                    },
                },
            },
            MuiListSubheader: {
                styleOverrides: {
                    root: {
                        backgroundColor: 'unset',
                        paddingLeft: '24px  !important',
                    },
                },
            },
            MuiButtonBase: {
                styleOverrides: {
                    root: {
                        backgroundColor: 'unset',
                        '&.MuiPickersDay-root': {
                            backgroundColor: 'unset',
                            '&:focus': {
                                '&.Mui-selected': {
                                    backgroundColor: customThemeConfig.main,
                                },
                            },
                        },
                        '&.MuiTabScrollButton-root': {
                            '&.Mui-disabled': {
                                opacity: 0.3,
                            },
                        },
                    },
                },
            },
            MuiAccordionSummary: {
                styleOverrides: {
                    expandIconWrapper: {
                        color: customThemeConfig.main,
                    },
                },
            },
            MuiCircularProgress: {
                styleOverrides: {
                    root: {
                        color: customThemeConfig.main,
                    },
                    circle: {
                        color: customThemeConfig.main,
                    },
                },
            },
            MuiListItem: {
                styleOverrides: {
                    root: {
                        '&.Mui-selected': {
                            color: `${customThemeConfig.link}!important`,
                            backgroundColor: `${alpha(customThemeConfig.main, 0.1)} !important`,
                        },
                        '&:hover': {
                            backgroundColor: `${alpha(customThemeConfig.main, 0.05)} !important`,
                        },
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        marginLeft: '0.75rem',
                        fontSize: '0.875rem',
                        lineHeight: '1rem',
                        fontWeight: 600,
                    },
                },
            },
        },
    });
};
