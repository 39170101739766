/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useContext, useMemo, useState } from 'react';
import { darken, Theme } from '@mui/material/styles';
import { createUseStyles } from 'react-jss';
import { SingleQueryDetails } from '../../../../api/queryApi';
import { QueryStageAccordion } from './QueryStageAccordion';
import { NoData } from '../../../../components/error/NoData';
import { QueryBasicSummary } from './QueryBasicSummary';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { ConfigContext } from '../../../../app/ConfigContextProvider';
import {
    QueryStageVisualizationMode,
    QueryStageVisualizationModeChooser,
} from './QueryStageVisualizationModeChooser';
import { QueryLivePlan } from '../advanced/live-plan/QueryLivePlan';
import { QueryLivePlanGraphFullScreen } from './QueryLivePlanGraphFullScreen';

const useStyles = createUseStyles((theme: Theme) => ({
    root: {
        padding: '16px 0',
    },
    header: {
        display: 'flex',
        margin: '1rem 0rem',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    noData: {
        marginTop: '5rem',
    },
    link: {
        cursor: 'pointer',
    },
    text: {
        fontFamily: theme.typography.fontFamily,
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.496px',
        '& a': {
            color: theme.palette.primary.main,
            textDecoration: 'none',
            '&:hover': {
                color: darken(theme.palette.primary.main, 0.5),
            },
        },
    },
    livePlanContainer: {
        height: '48vh',
        display: 'flex',
        alignItems: 'stretch',
    },
}));

interface QueryStagesProps {
    query: SingleQueryDetails;
}

export const QueryStagesTab: React.FunctionComponent<QueryStagesProps> = ({ query }) => {
    const classes = useStyles();
    const docsUrl = useContext(ConfigContext)?.docsUrl + '/insights.html';
    const [queryStageVisualizationMode, setQueryStageVisualizationMode] = useState(
        QueryStageVisualizationMode.LIST
    );

    const getListOfCpuTimes = (): number[] => {
        return query.stages?.map((stage) => stage.cpuTime) ?? [];
    };

    const maxCpuTime = useMemo(() => {
        return Math.max(...getListOfCpuTimes());
    }, [...getListOfCpuTimes()]);

    return (
        <Box className={classes.root}>
            <Typography variant={'h5'}>Stages</Typography>
            <Typography variant="body1" className={classes.text}>
                Stages encompass all the tasks of the work described by the stage&rsquo;s plan
                fragment.&nbsp;
                <Link
                    onClick={() => {
                        window.open(docsUrl, '_blank');
                    }}
                    className={classes.link}>
                    Read more information
                </Link>{' '}
                on stages and tasks in our documentation.
            </Typography>
            <div className={classes.header}>
                <QueryBasicSummary query={query} />
                <div>
                    <QueryStageVisualizationModeChooser
                        mode={queryStageVisualizationMode}
                        onChange={setQueryStageVisualizationMode}
                    />
                </div>
            </div>
            {query.stages?.length ? (
                queryStageVisualizationMode === QueryStageVisualizationMode.LIST ? (
                    query.stages.map((stage) => {
                        return (
                            <QueryStageAccordion
                                stage={stage}
                                key={stage.id}
                                maxCpuTime={maxCpuTime}
                            />
                        );
                    })
                ) : (
                    <>
                        <div className={classes.livePlanContainer}>
                            <QueryLivePlan queryId={query.queryId} />
                        </div>
                        <div style={{ display: 'flow-root' }}>
                            <QueryLivePlanGraphFullScreen queryId={query.queryId} />
                        </div>
                    </>
                )
            ) : (
                <div className={classes.noData}>
                    <NoData height={300} icon={'ban'} text={'No stage data available to display'} />
                </div>
            )}
        </Box>
    );
};
