/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import React, { ReactElement, useEffect, useState, createContext } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import { CustomThemeConfig, getCustomTheme } from '../api/themeApi';
import { materialUiThemeCustom } from '../themes/materialUiThemeCustom';
import { materialUiTheme } from '../themes/materialUiTheme';
import { materialUiThemeDark } from '../themes/materialUiThemeDark';

export type ThemeMode = 'light' | 'dark';

const getDefaultThemeMode = () => (localStorage.getItem('mode') as ThemeMode) ?? 'light';

const getTheme = (mode: ThemeMode) => (mode === 'light' ? materialUiTheme : materialUiThemeDark);

interface UIThemeContextProviderProps {
    children: ReactElement;
}

interface ThemeContextState {
    mode: ThemeMode;
    theme: Theme;
    toggleUITheme: () => void;
    customThemeConfig?: CustomThemeConfig;
}

export const UIThemeContext = createContext<ThemeContextState>({
    mode: getDefaultThemeMode(),
    theme: getTheme(getDefaultThemeMode()),
    toggleUITheme: () => {
        // overwritten in a provider
    },
});

export const UIThemeContextProvider: React.FunctionComponent<UIThemeContextProviderProps> = ({
    children,
}) => {
    const [themeContext, setThemeContext] = useState<ThemeContextState>(() => {
        const mode = getDefaultThemeMode();
        return {
            mode,
            theme: getTheme(mode),
            toggleUITheme: () => {
                setThemeContext((prev) => {
                    const newMode = prev.mode === 'light' ? 'dark' : 'light';
                    localStorage.setItem('mode', newMode);
                    return {
                        ...prev,
                        mode: newMode,
                        theme: getTheme(newMode),
                    };
                });
            },
        };
    });

    useEffect(() => {
        getCustomTheme()
            .then((themeConfig) => {
                if (themeConfig) {
                    setThemeContext((prev) => ({
                        ...prev,
                        theme: materialUiThemeCustom(themeConfig),
                        customThemeConfig: themeConfig,
                    }));
                }
            })
            .catch((e) => {
                console.log('Cannot load custom theme', e);
                // ignore errors
            });
    }, []);

    return <UIThemeContext.Provider value={themeContext}>{children}</UIThemeContext.Provider>;
};

export const useThemeMode = (): ThemeMode => {
    return useTheme().palette.mode;
};
