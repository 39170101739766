/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useContext, useMemo } from 'react';

import { createUseStyles } from 'react-jss';
import { Theme } from '@mui/material/styles';
import { SidePanelItem } from './SidePanelItem';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import CodeIcon from '@mui/icons-material/Code';
import RolesIcon from '@mui/icons-material/Security';
import AuditLogsIcon from '@mui/icons-material/ListAlt';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import CloudIcon from '@mui/icons-material/Cloud';
import DashboardIcon from '@mui/icons-material/Dashboard';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { ClusterInformation } from '../../components/menu/ClusterInfoBox';
import { ConfigContext, uiFeatureEnabled } from '../../app/ConfigContextProvider';
import { paletteSwitch } from '../../themes/palette';
import clsx from 'clsx';
import {
    dataProductManagementPath,
    domainManagementPath,
} from '../../features/dataproduct/routing/dataProductRoutingUtils';
import { UiFeatures } from '../../api/configApi';
import { SidePanelParentItem } from './SidePanelParentItem';
import { RoleContext } from '../../features/biac/CurrentRoleContext';
import { SYSTEM_ROLE_ID } from '../../api/biac/biacRolesApi';
import { Styles } from 'jss';

const DRAWER_WIDTH = 304;

const useStyles = createUseStyles((theme: Theme) => ({
    drawer: {
        width: `${DRAWER_WIDTH}px`,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxShadow: theme.shadows[4],
        zIndex: 2,
    },
    drawerOpen: {
        width: DRAWER_WIDTH,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen / 2,
        }),
        overflowX: 'hidden',
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen / 2,
        }),
        overflowX: 'hidden',
        width: `calc(${theme.spacing(9)})`,
        [theme.breakpoints.up('sm')]: {
            width: `calc(${theme.spacing(9)})`,
            [theme.breakpoints.up('sm')]: {
                width: `calc(${theme.spacing(9)})`,
            },
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            // necessary for content to be below app bar
            ...(theme.mixins.toolbar as Styles),
        },
        sidePanelList: {
            paddingTop: 'unset',
        },
        sidePanelListSubheader: {
            color: paletteSwitch(theme).black54,
            fontSize: '14px',
            fontWeight: 600,
            letterSpacing: '0.1px',
            textTransform: 'uppercase',
            paddingLeft: '24px',
            lineHeight: '48px',
            transition: '0.1s cubic-bezier(0.4, 0, 1,1)',
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        // necessary for content to be below app bar
        ...(theme.mixins.toolbar as Styles),
    },
    sidePanelList: {
        paddingTop: 'unset',
    },
    sidePanelListSubheader: {
        color: paletteSwitch(theme).black38,
        fontSize: '14px',
        fontWeight: 600,
        letterSpacing: '0.1px',
        textTransform: 'uppercase',
        paddingLeft: '24px',
        lineHeight: '48px',
        transition: '0.1s cubic-bezier(0.4, 0, 1,1)',
    },
    compactNav: {
        opacity: 0,
        transition: 'opacity 0.1s cubic-bezier(0.4, 0, 1,1)',
    },
    clusterInfoBox: {
        marginTop: 'auto',
        marginBottom: '0.5rem',
        marginLeft: '0.5rem',
        transition: '0.1s cubic-bezier(0.4, 0, 1,1)',
    },
    drawerPaper: {
        backgroundColor: paletteSwitch(theme).bgLevel2,
        borderRight: 'unset',
    },
}));

interface SidePanelProps {
    openNav: boolean;
    setOpenNav: (open: boolean) => void;
}

export const SidePanel: React.FunctionComponent<SidePanelProps> = ({ openNav, setOpenNav }) => {
    const classes = useStyles();
    const clusterInfo = useContext(ConfigContext);
    const accessControlEnabled = clusterInfo?.accessControlEnabled;
    const auditEnabled = clusterInfo?.auditEnabled;
    const persistenceEnabled = clusterInfo?.persistenceEnabled;
    const querySectionEnabled = uiFeatureEnabled(UiFeatures.QUERY_EDITOR);
    const role = useContext(RoleContext);
    const isSysAdminRole = useMemo(() => {
        if (role !== 'disabled' && role.currentRole !== 'ALL') {
            return role.currentRole.id === SYSTEM_ROLE_ID;
        }
        return false;
    }, [role]);

    return (
        <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: openNav,
                [classes.drawerClose]: !openNav,
            })}
            classes={{
                paper: clsx(classes.drawerPaper, {
                    [classes.drawerOpen]: openNav,
                    [classes.drawerClose]: !openNav,
                }),
            }}
            anchor="left"
            open={openNav}>
            <div className={classes.toolbar} />
            <List className={classes.sidePanelList}>
                {querySectionEnabled && (
                    <SidePanelParentItem
                        text="Query"
                        icon={CodeIcon}
                        openNav={openNav}
                        setOpenNav={setOpenNav}
                        paths={['/ide', '/saved-queries']}>
                        <SidePanelItem
                            text="Query editor"
                            to="/ide"
                            openNav={openNav}
                            uiFeature={UiFeatures.QUERY_EDITOR}
                        />
                        {persistenceEnabled && (
                            <SidePanelItem
                                text="Saved queries"
                                to="/saved-queries"
                                openNav={openNav}
                                uiFeature={UiFeatures.QUERY_EDITOR}
                            />
                        )}
                    </SidePanelParentItem>
                )}

                <SidePanelItem
                    icon={WidgetsOutlinedIcon}
                    text="Data products"
                    to={dataProductManagementPath}
                    openNav={openNav}
                    uiFeature={UiFeatures.DATA_PRODUCTS}
                />
                <SidePanelItem
                    icon={VerticalSplitIcon}
                    text="Domain management"
                    to={domainManagementPath}
                    openNav={openNav}
                    uiFeature={UiFeatures.DATA_PRODUCTS}
                />
                <ListSubheader
                    className={clsx(classes.sidePanelListSubheader, {
                        [classes.compactNav]: !openNav,
                    })}>
                    Insights
                </ListSubheader>
                <SidePanelItem
                    to={['/', '/index.html']}
                    icon={DashboardIcon}
                    text="Overview"
                    openNav={openNav}
                    uiFeature={UiFeatures.OVERVIEW}
                />
                <SidePanelItem
                    to="/query"
                    icon={QueryStatsIcon}
                    text="Query overview"
                    openNav={openNav}
                    uiFeature={UiFeatures.QUERIES}
                />
                <SidePanelItem
                    to="/cluster"
                    icon={CloudIcon}
                    text="Cluster history"
                    openNav={openNav}
                    uiFeature={UiFeatures.CLUSTER_HISTORY}
                />
                <SidePanelItem
                    to="/usage"
                    icon={EqualizerIcon}
                    text="Usage metrics"
                    openNav={openNav}
                    uiFeature={UiFeatures.USAGE_METRICS}
                />

                {/* Separator */}
                {accessControlEnabled && (
                    <>
                        <ListSubheader
                            className={clsx(classes.sidePanelListSubheader, {
                                [classes.compactNav]: !openNav,
                            })}>
                            Security
                        </ListSubheader>

                        <SidePanelParentItem
                            text="Access control"
                            icon={RolesIcon}
                            openNav={openNav}
                            setOpenNav={setOpenNav}
                            paths={['/roles', '/masks-and-filters']}>
                            <SidePanelItem
                                to="/roles"
                                text="Roles and privileges"
                                openNav={openNav}
                            />
                            {isSysAdminRole && (
                                <SidePanelItem
                                    to="/masks-and-filters"
                                    text="Masks and filters"
                                    openNav={openNav}
                                />
                            )}
                        </SidePanelParentItem>

                        {auditEnabled && (
                            <SidePanelItem
                                to="/log/audit"
                                icon={AuditLogsIcon}
                                text="Audit log"
                                openNav={openNav}
                            />
                        )}
                    </>
                )}
            </List>
            <div className={classes.clusterInfoBox}>
                <ClusterInformation openNav={openNav} />
            </div>
        </Drawer>
    );
};
