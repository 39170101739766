/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from 'react';
import { AppRouter } from './AppRouter';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import 'moment-duration-format';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Provider } from 'react-redux';
import { store } from './store';
import { Theming } from './Theming';
import { UIThemeContextProvider } from './UIThemeContextProvider';

export const App: React.FunctionComponent = () => {
    return (
        <UIThemeContextProvider>
            <Theming>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Provider store={store}>
                        <AppRouter />
                    </Provider>
                </LocalizationProvider>
            </Theming>
        </UIThemeContextProvider>
    );
};
