/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import Button from '@mui/material/Button';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faProjectDiagram, faList } from '@fortawesome/pro-regular-svg-icons';
import ButtonGroup from '@mui/material/ButtonGroup';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { paletteSwitch } from '../../../../themes/palette';
import { Theme } from '@mui/material/styles';

export enum QueryStageVisualizationMode {
    LIST = 'list',
    GRAPH = 'graph',
}

interface QueryStageVisualizationModeChooserProps {
    mode: QueryStageVisualizationMode;
    onChange: (newMode: QueryStageVisualizationMode) => void;
}

export const QueryStageVisualizationModeChooser: React.FunctionComponent<
    QueryStageVisualizationModeChooserProps
> = ({ mode, onChange }: QueryStageVisualizationModeChooserProps) => {
    const styles = useStyles();
    return (
        <ButtonGroup variant="outlined" color="primary">
            <Button
                className={clsx(styles.button, {
                    [styles.activeButton]: mode === QueryStageVisualizationMode.LIST,
                })}
                onClick={() => onChange(QueryStageVisualizationMode.LIST)}>
                <FontAwesomeIcon icon={faList} />
                <span className={styles.buttonText}>List</span>
            </Button>
            <Button
                className={clsx(styles.button, {
                    [styles.activeButton]: mode === QueryStageVisualizationMode.GRAPH,
                })}
                onClick={() => onChange(QueryStageVisualizationMode.GRAPH)}>
                <FontAwesomeIcon icon={faProjectDiagram} />
                <span className={styles.buttonText}>Graph</span>
            </Button>
        </ButtonGroup>
    );
};

const useStyles = createUseStyles((theme: Theme) => ({
    button: {
        fontSize: '1.125rem',
    },
    buttonText: {
        textTransform: 'initial',
        fontSize: '0.875rem',
        fontWeight: 600,
        marginLeft: '0.5rem',
        lineHeight: '1.25rem',
    },
    activeButton: {
        backgroundColor: theme.palette.primary.main,
        color: paletteSwitch(theme).main,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
    },
}));
