/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from 'react';
import Toolbar from '@mui/material/Toolbar';
import { palette } from '../../themes/palette';
import Typography from '@mui/material/Typography';
import { createUseStyles } from 'react-jss';
import { Theme } from '@mui/material/styles';
import { shadows } from '../../themes/shadows';
import logo from '../../images/announcement.svg';

const useStyles = createUseStyles((theme: Theme) => ({
    toolBar: {
        position: 'absolute',
        width: '100%',
        color: palette.white,
        boxShadow: shadows[1],
        padding: '0.5rem',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        zIndex: theme.zIndex.appBar,
    },
    bannerBody: { alignItems: 'center', marginLeft: '0.5rem' },
    bannerText: {
        fontFamily: theme.typography.heading.fontFamily,
        fontWeight: 400,
        paddingTop: '2px',
        lineHeight: '27px',
        letterSpacing: '1.5px',
        margin: '0 0.5rem 0 2.5rem',
        color: palette.white,
    },
}));

interface AppBannerProps {
    background?: string;
    bannerText: string;
}

export const AppBanner: React.FunctionComponent<AppBannerProps> = ({
    background = palette.teal,
    bannerText,
}) => {
    const classes = useStyles();
    return (
        <Toolbar
            className={classes.toolBar}
            style={{
                background,
            }}>
            <div className={classes.bannerBody}>
                <img style={{ position: 'fixed' }} src={logo} alt="Announcement Logo" />
                <Typography className={classes.bannerText}>{bannerText}</Typography>
            </div>
        </Toolbar>
    );
};
