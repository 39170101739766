/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useContext } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { NodeSummaries } from '../../api/overviewApi';
import { paletteSwitch } from '../../themes/palette';
import { createUseStyles } from 'react-jss';
import Box from '@mui/material/Box';
import { ConfigContext } from '../../app/ConfigContextProvider';
import { formatBytes } from '../../utils/formatBytes';
import { Theme } from '@mui/material/styles';

interface WorkersSummaryProps {
    nodeSummaries: NodeSummaries | 'loading' | 'error';
    lastClusterScaling: string;
    className: string;
}

const useStyles = createUseStyles((theme: Theme) => ({
    textBox: {
        fontSize: '0.875rem',
        color: paletteSwitch(theme).black54,
        lineHeight: '1.125rem',
    },
    textLine: {
        marginTop: '4px',
    },
    value: {
        color: paletteSwitch(theme).black,
    },
}));

export const WorkersSummary: React.FunctionComponent<WorkersSummaryProps> = ({
    nodeSummaries,
    className,
    lastClusterScaling,
}) => {
    const classes = useStyles();
    const allQueries = useContext(ConfigContext)?.allQueries;
    const summaryVisible = allQueries
        ? useMediaQuery('(min-width:1200px)')
        : useMediaQuery('(min-width:1400px)');

    const currentWorkersCount = (): number | string => {
        if (Array.isArray(nodeSummaries)) {
            return nodeSummaries.length;
        }

        return '-';
    };

    const workersRange = (
        valueProvider: (summaries: NodeSummaries[number]) => number
    ): [number | undefined, number | undefined] => {
        if (Array.isArray(nodeSummaries)) {
            const heaps = nodeSummaries.map((s) => valueProvider(s));
            if (heaps.length === 0) {
                return [undefined, undefined];
            }

            return [Math.min(...heaps), Math.max(...heaps)];
        }

        return [undefined, undefined];
    };

    const renderRange = (
        [inputMin, inputMax]: [number | undefined, number | undefined],
        formatter: (value: number) => string = (value) => `${value}`
    ): React.ReactNode => {
        const min = inputMin !== undefined ? formatter(inputMin) : undefined;
        const max = inputMax !== undefined ? formatter(inputMax) : undefined;

        if (min === undefined) {
            return <span className={classes.value}>-</span>;
        } else if (max === undefined || min === max) {
            return <span className={classes.value}>{min}</span>;
        } else {
            return (
                <>
                    <span className={classes.value}>{min} </span> to{' '}
                    <span className={classes.value}>{max}</span>
                </>
            );
        }
    };

    if (!summaryVisible) {
        return null;
    }

    return (
        <Box mt={1} display="flex" className={className} style={{ marginTop: 0 }}>
            <Box my="auto" ml={3} className={classes.textBox}>
                <Box className={classes.textLine}>
                    Workers running: <span className={classes.value}>{currentWorkersCount()}</span>
                </Box>
                <Box className={classes.textLine}>
                    Workers processors: {renderRange(workersRange(({ processors }) => processors))}
                </Box>
                <Box className={classes.textLine}>
                    Workers heap size:{' '}
                    {renderRange(
                        workersRange(({ heapAvailable }) => heapAvailable),
                        formatBytes
                    )}
                </Box>
                <Box className={classes.textLine}>
                    Last cluster scaling:{' '}
                    <span className={classes.value}>{lastClusterScaling}</span>
                </Box>
            </Box>
        </Box>
    );
};
