/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useContext } from 'react';
import Link from '@mui/material/Link';
import { createUseStyles } from 'react-jss';
import { Biac } from './Biac';
import { ConfigContext } from '../../app/ConfigContextProvider';
import { Spinner } from '../spinner/Spinner';
import { UiFeatures } from '../../api/configApi';
import { Theme } from '@mui/material/styles';

const useStyles = createUseStyles((theme: Theme) => ({
    link: {
        color: theme.palette.primary.main,
        display: 'block',
    },
}));

interface AuthorizedUserBiacProps {
    uiFeature: UiFeatures;
    children: React.ReactElement;
}

export const AuthorizedUserBiac: React.FunctionComponent<AuthorizedUserBiacProps> = ({
    uiFeature,
    children,
}) => {
    const classes = useStyles();
    const clusterInfo = useContext(ConfigContext);

    if (clusterInfo === undefined) {
        return <Spinner position="relative" delay={500} />;
    }
    const docsUrl = clusterInfo.docsUrl + '/insights/index.html#insights-configuration';

    return (
        <Biac
            title={'This view is accessible only to authorized users'}
            description={
                <p>
                    You can learn about configuring authorized users at:
                    <Link className={classes.link} rel="noreferrer" target="_blank" href={docsUrl}>
                        {docsUrl}
                    </Link>
                </p>
            }
            enabled={() =>
                clusterInfo?.allowedUiFeatures.some((info) => {
                    return info.feature === uiFeature;
                })
            }>
            {children}
        </Biac>
    );
};
