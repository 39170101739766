/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import Box from '@mui/material/Box';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { paletteSwitch } from '../../../themes/palette';
import { Theme } from '@mui/material/styles';

const useStyles = createUseStyles((theme: Theme) => ({
    root: {
        fontWeight: 600,
        fontSize: '0.625rem',
        lineHeight: '1rem',
        letterSpacing: '1.5px',
        display: 'flex',
        color: paletteSwitch(theme).nebulaNavy,
    },
    overviewLink: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    separator: {
        marginLeft: '10px',
        marginRight: '10px',
    },
}));

interface QueryDetailsBreadcrumbsProps {
    queryId: string;
    goToOverview: () => void;
}

export const QueryDetailsBreadcrumbs: React.FunctionComponent<QueryDetailsBreadcrumbsProps> = ({
    queryId,
    goToOverview,
}) => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <div className={classes.overviewLink} onClick={goToOverview}>
                QUERY OVERVIEW
            </div>
            <div className={classes.separator}>|</div>
            <div>QUERY ID: {queryId}</div>
        </Box>
    );
};
