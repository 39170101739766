/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { alpha, Theme } from '@mui/material/styles';

export const palette = {
    main: '#ffffff',
    teal: '#00a7b5',
    darkTeal: '#00717a',
    purple: '#b835a0',
    fusionFuchsia_12: 'rgba(184, 53, 160, 0.12)',
    fusionFuchsia100: '#e7badc',
    fusionFuchsia050: '#f5e3f1',
    fusionFuchsia025: '#f5ecf3',
    lavender: '#6c4e98',
    green: '#4caf5c',
    orange: 'orange',
    nightBlueLight: '#2a375d',
    nightBlue: '#041440',
    nightBlueDark: '#000a2c',
    customBlue: '#6876a3',
    white: '#ffffff',
    black: 'rgba(0, 0, 0, 0.87)',
    black54: 'rgba(0, 0, 0, 0.54)',
    black38: 'rgba(0,0,0,0.38)',
    black20: 'rgba(0,0,0,0.20)',
    black14: 'rgba(0,0,0,0.14)',
    black12: 'rgba(0, 0, 0, 0.12)',
    black10: 'rgba(0, 0, 0, 0.10)',
    black08: 'rgba(0, 0, 0, 0.08)',
    black04: 'rgba(0, 0, 0, 0.04)',
    black3: 'rgba(0, 0, 0, 0.03)',
    black2: 'rgba(0, 0, 0, 0.02)',
    black1: 'rgba(0, 0, 0, 0.01)',
    link: '#0077aa',
    opaqueLightGrey: '#eceef2',
    codeHighlightGrey: '#f0f0f0',
    nebulaNavy: '#06184C',
    nebulaNavy700: '#1a306e',
    nebulaNavy500: '#293f81',
    nebulaNavy400: '#495a92',
    nebulaNavy300: '#6876a3',
    nebulaNavy200: '#919bbd',
    nebulaNavy100: '#bcc2d8',
    nebulaNavy50: '#e4e7ef',
    nebulaNavy25: '#f8f9fB',
    nebulaNavy15: '#f5f6f9',
    error: '#f04667',
    errorLight: alpha('#f04667', 0.1),
    errorDark: '#cf1438',
    scrollbar: '#919BBD',
    hilite: '#db7b2e',
    hiliteLight: 'rgba(229, 128, 48, 0.15)',
    hiliteDark: '#b05400',
    success: '#44a654',
    successLight: alpha('#44a654', 0.12),
    successDark: '#067d2f',
    info: '#00a2b0',
    infoLight: alpha('#00a2b0', 0.1),
    infoDark: '#007a85',
    warning: '#ffc800',
    warningLight: alpha('#ffc800', 0.15),
    warningDark: '#8c6d00',
    bgLevel2: '#ffffff',
    bgLevel3: '#ffffff',
    bgLevel4: '#ffffff',
    toolTip: '#D9D9D9',
    sqlHighlightBackground: 'rgba(236, 238, 242, 0.3)',
    sqlHighlightBorder: 'rgba(188,194,216, 0.3)',
};

export const paletteSwitch = (theme: Theme) => {
    return theme.palette.mode === 'light' ? palette : darkThemePalette;
};

export const darkThemePalette = {
    main: '#08122E',
    purple: '#FA87E2',
    fusionFuchsia025: '#8C2484',
    nebulaNavy: '#f5f6f9',
    nebulaNavy700: '#e4e7ef',
    nebulaNavy500: '#919bbd',
    nebulaNavy400: '#6876a3',
    nebulaNavy300: '#495a92',
    nebulaNavy200: '#293f81',
    nebulaNavy100: '#233979',
    nebulaNavy50: '#1a306e',
    nebulaNavy25: '#122762',
    nebulaNavy15: '#06184C',
    white: '#08122E',
    black: 'rgba(255, 255, 255, 0.87)',
    black54: 'rgba(255, 255, 255, 0.54)',
    black38: 'rgba(255, 255, 255, 0.38)',
    black20: 'rgba(255, 255, 255, 0.20)',
    black14: 'rgba(255, 255, 255, 0.14)',
    black12: 'rgba(255, 255, 255, 0.12)',
    black10: 'rgba(255, 255, 255, 0.10)',
    black08: 'rgba(255, 255, 255, 0.08)',
    black04: 'rgba(255, 255, 255, 0.04)',
    black3: 'rgba(255, 255, 255, 0.03)',
    black2: 'rgba(255, 255, 255, 0.02)',
    black1: 'rgba(255, 255, 255, 0.01)',
    bgLevel2: '#0F1837',
    bgLevel3: '#112047',
    bgLevel4: '#122762',
    toolTip: '#1a306e',
    teal: '#80D3D4',
    scrollbar: '#6876A3',
    darkTeal: '#1C9395',
    fusionFuchsia_12: 'rgba(184, 53, 160, 0.25)',
    fusionFuchsia100: '#e7badc',
    fusionFuchsia050: '#A6339C',
    lavender: '#6c4e98',
    green: '#4caf5c',
    orange: 'orange',
    nightBlueLight: '#2a375d',
    nightBlue: '#112047',
    nightBlueDark: '#000a2c',
    customBlue: '#6876a3',
    link: '#0077aa',
    opaqueLightGrey: '#122762',
    codeHighlightGrey: '#112047',
    error: '#f04667',
    errorLight: alpha('#DA3656', 0.1),
    errorDark: '#DA3656',
    hilite: '#db7b2e',
    hiliteLight: 'rgba(229, 128, 48, 0.15)',
    hiliteDark: '#db7b2e',
    success: '#44a654',
    successLight: alpha('#44a654', 0.12),
    successDark: '#44A654',
    info: '#00a2b0',
    infoLight: alpha('#00a2b0', 0.1),
    infoDark: '#00A2B0',
    warning: '#ffc800',
    warningLight: alpha('#ffc800', 0.15),
    warningDark: '#FFC800',
    sqlHighlightBackground: alpha('#122762', 0.3),
    sqlHighlightBorder: 'none',
};

export const stateColor = {
    PLANNED: '#F2C94C',
    SCHEDULING: '#9B51E0',
    SCHEDULING_SPLITS: '#BB6BD9',
    SCHEDULED: '#D398EA',
    RUNNING: '#00A7B5',
    FLUSHING: '#2F80ED',
    FINISHED: '#4CAF5C',
    CANCELED: '#E58030',
    ABORTED: '#EB5757',
    FAILED: '#DA133A',
};

export const defaultChartPalette = [
    '#65BF78',
    '#F4C65C',
    '#2076FC',
    '#FC8721',
    '#B835A1',
    '#6C4E9B',
    '#b835a0',
];

export const sqlHighlightStyle = {
    border: '1px solid rgba(188,194,216, 0.3)',
    backgroundColor: 'rgba(236, 238, 242, 0.3)',
    borderRadius: '0.25rem',
};
