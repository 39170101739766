/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { ExtendButtonBase } from '@mui/material/ButtonBase';
import { TabTypeMap } from '@mui/material/Tab';
import { useTabContext } from '@mui/lab/TabContext';
import clsx from 'clsx';
import React from 'react';
import { useDiscussionContext } from '../../discussion/DiscussionContext';
import { createUseStyles } from 'react-jss';
import { paletteSwitch } from '../../../../themes/palette';
import { DataProductTabs } from '../DataProductTabs';
import { DefaultTab } from '../../../../components/tab/DefaultTab';
import { Theme } from '@mui/material/styles';

const useClasses = createUseStyles((theme: Theme) => ({
    commentCount: {
        padding: '0 0.5rem',
        borderRadius: '0.5rem',
    },
    commentCountActive: {
        backgroundColor: paletteSwitch(theme).fusionFuchsia050,
    },
    commentCountInactive: {
        backgroundColor: paletteSwitch(theme).opaqueLightGrey,
    },
}));

export const DiscussionTab: ExtendButtonBase<TabTypeMap> = (props: TabTypeMap['props']) => {
    const { data: comments } = useDiscussionContext();
    const internalClasses = useClasses();
    const tabContext = useTabContext();
    return (
        <DefaultTab
            label={
                <div>
                    <span>Discussion</span>{' '}
                    {!!comments && (
                        <span
                            className={clsx(internalClasses.commentCount, {
                                [internalClasses.commentCountActive]:
                                    tabContext?.value === DataProductTabs.DISCUSSION_TAB,
                                [internalClasses.commentCountInactive]:
                                    tabContext?.value !== DataProductTabs.DISCUSSION_TAB,
                            })}>
                            {comments.length}
                        </span>
                    )}
                </div>
            }
            disableRipple
            {...props}
        />
    );
};
