/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { createUseStyles } from 'react-jss';
import { paletteSwitch } from '../../themes/palette';
import { Theme } from '@mui/material/styles';

export const useTableStyles = createUseStyles((theme: Theme) => ({
    table: {
        border: `1px solid ${paletteSwitch(theme).nebulaNavy50}`,
        '& tbody tr:last-child td': {
            borderBottom: 'none',
        },
        display: 'flex',
    },
    headerCell: {
        backgroundColor: paletteSwitch(theme).white,
        fontWeight: 600,
        letterSpacing: '0.5px',
        fontFamily: theme.typography.fontFamily,
    },
    pagination: {
        backgroundColor: paletteSwitch(theme).white,
        height: '3.5rem',
        borderTop: `1px solid ${paletteSwitch(theme).nebulaNavy50}`,
        bottom: '0rem',
        position: 'sticky',
    },
    paginationRoot: {
        borderBottom: 'none',
    },
    paginationInput: {
        '&.Mui-focused': {
            backgroundColor: paletteSwitch(theme).nebulaNavy25,
        },
    },
    paginationMenuItem: {
        '&.Mui-selected': {
            backgroundColor: `${paletteSwitch(theme).opaqueLightGrey} !important`,
        },
        '&:hover': {
            backgroundColor: paletteSwitch(theme).nebulaNavy25,
        },
    },
    paginationActions: {
        marginRight: '1.563rem',
    },
    link: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    sortableHeaderCell: {
        minWidth: '9.375rem',
    },
    sortLabel: {
        '&:hover': {
            color: paletteSwitch(theme).black,
        },
        '&:focus': {
            color: paletteSwitch(theme).black,
        },
    },
    cell: {
        height: '3.5rem',
        color: paletteSwitch(theme).black,
        fontFamily: theme.typography.fontFamily,
        fontSize: '0.875rem',
        fontWeight: 400,
        lineHeight: '1.5rem',
        letterSpacing: '0.496px',
    },
    cellRoot: {
        padding: '0.5rem 1rem',
    },
    cellIcon: {
        height: '1rem',
    },
    cellContent: {
        marginTop: 'auto',
        marginBottom: 'auto',
    },
}));
