/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import React from 'react';
import { paletteSwitch } from '../themes/palette';
import { createUseStyles } from 'react-jss';
import { Theme } from '@mui/material/styles';
import Backdrop from '@mui/material/Backdrop';
import { Spinner } from './spinner/Spinner';

const useStyles = createUseStyles((theme: Theme) => ({
    root: {
        zIndex: 5000,
        backgroundColor: paletteSwitch(theme).black38,
        color: theme.palette.primary.main,
    },
}));

interface LoadingBackdropProps {
    opened: boolean;
}

export const LoadingBackdrop = ({ opened }: LoadingBackdropProps) => {
    const classes = useStyles();

    return (
        <Backdrop open={opened} className={classes.root}>
            <Spinner position="absolute" />
        </Backdrop>
    );
};
