/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { createUseStyles } from 'react-jss';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import React, { Dispatch, useCallback, useEffect, useMemo, useState } from 'react';
import groupBy from 'lodash/groupBy';
import clsx from 'clsx';
import {
    DataProductForm,
    Dataset,
    DatasetType,
    getSupportedCatalogs$,
    TagValue,
} from '../../../api/dataProduct/dataProductApi';
import { paletteSwitch } from '../../../themes/palette';
import { useDataProductTrinoClient } from '../trinoClient/useDataProductTrinoClient';
import { maximumFreeTextLength } from '../util/formUtils';
import { DataProductDataset } from './DataProductDataset';
import { DataProductWizardNavigation } from './DataProductWizardNavigation';
import { ColumnNameDescription, PublishFormAction } from './publishFormReducer';
import { ErrorIndicator } from '../../../components/error/ErrorIndicator';
import { Spinner } from '../../../components/spinner/Spinner';
import { QueryEditorWithDialog } from '../../../components/sql-highlight/QueryEditorWithDialog';
import { DatasetTypeSection } from './DatasetTypeSection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import Typography from '@mui/material/Typography';
import { PreviewDataset } from '../components/PreviewDataset';
import { useTabStyles } from '../../../themes/useTabStyles';
import { DatasetNameInput } from './DatasetNameInput';
import { DefaultTab } from '../../../components/tab/DefaultTab';
import { Theme } from '@mui/material/styles';

interface DataProductDefinitionProps {
    dataProductForm: DataProductForm;
    tags: TagValue[];
    selectedDatasetIndex: number;
    error: string | undefined;
    publishFormDispatch: Dispatch<PublishFormAction>;
    setWizardStep: (newValue: number) => void;
    setSelectedDatasetIndex: (newValue: number) => void;
    setError: (newValue: string | undefined) => void;
}

const useStyles = createUseStyles((theme: Theme) => ({
    helperText: {
        fontSize: '1rem',
        color: paletteSwitch(theme).black54,
        paddingBottom: '1.5rem',
    },
    lockIcon: {
        cursor: 'default',
        marginRight: '4px',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    textField: {
        width: '33.3%',
    },
    textArea: {
        paddingBottom: '1.5rem',
        width: '100%',
    },
    addIcon: {
        marginRight: '0.625rem',
    },
    fetchButton: {
        float: 'right',
    },
    navBarSpacer: {
        marginBottom: '1.5rem',
    },
}));

export const DataProductDefinition: React.FunctionComponent<DataProductDefinitionProps> = ({
    dataProductForm,
    tags,
    selectedDatasetIndex,
    error,
    publishFormDispatch,
    setWizardStep,
    setSelectedDatasetIndex,
    setError,
}) => {
    const classes = useStyles();
    const tabClasses = useTabStyles();
    const [duplicateDatasetNames, setDuplicateDatasetNames] = useState<string[]>([]);
    const isDuplicateDatasetName = useMemo(
        () => duplicateDatasetNames.length > 0,
        [duplicateDatasetNames]
    );
    const [isRefreshIntervalInvalid, setIsRefreshIntervalInvalid] = useState<boolean>(false);
    const [isIncrementalColumnInvalid, setIsIncrementalColumnInvalid] = useState<boolean>(false);
    const activeDataset: Dataset | undefined = dataProductForm.datasets[selectedDatasetIndex] as
        | Dataset
        | undefined;
    const namesWhichCanNotBeUsedForActiveDataset = dataProductForm.datasets
        .filter((_, index) => index !== selectedDatasetIndex)
        .map(({ name }) => name);

    const [isMaterializedViewEnabled, setIsMaterializedViewEnabled] = useState<boolean>(false);
    useEffect(() => {
        const subscription = getSupportedCatalogs$().subscribe({
            next: (details) => {
                setIsMaterializedViewEnabled(
                    details.find((catalog) => catalog.catalogName === dataProductForm.catalogName)
                        ?.isMaterializedViewEnabled || false
                );
            },
            error: (error) => {
                setError(error.message);
            },
        });
        return () => subscription.unsubscribe();
    }, [dataProductForm.catalogName]);

    const updateActiveDataset = (dataset: Partial<Dataset>) => {
        publishFormDispatch({
            type: 'updateDataset',
            datasetIndex: selectedDatasetIndex,
            dataset,
        });
    };

    useEffect(() => {
        const datasetNamesGrouped = groupBy(dataProductForm.datasets.map(({ name }) => name));
        const duplicateNames = Object.keys(datasetNamesGrouped).filter(
            (name) => datasetNamesGrouped[name].length > 1
        );
        setDuplicateDatasetNames(duplicateNames);
    }, [dataProductForm.datasets]);

    const addNewDataset = () => {
        publishFormDispatch({ type: 'addDataset' });
        if (dataProductForm.datasets.length > 0) {
            setSelectedDatasetIndex(dataProductForm.datasets.length);
        }
    };

    const datasetSchema = activeDataset?.columns;

    function updateColumnDescription(column: ColumnNameDescription) {
        publishFormDispatch({
            type: 'updateColumn',
            datasetIndex: selectedDatasetIndex,
            column,
        });
    }

    const columnMetadataTrinoClient = useDataProductTrinoClient(
        {
            onReceiveColumnMetadata: (metadata) => {
                const nameToDescription = datasetSchema
                    ?.filter(({ description }) => description)
                    .reduce(
                        (map, current) => map.set(current.name, current.description),
                        new Map<string, string>()
                    );
                const columnNames = metadata.map((item) => item.name);
                const hasDuplicate = columnNames.some(
                    (item, idx) => columnNames.indexOf(item) != idx
                );
                if (hasDuplicate) {
                    setShowColumnsResponse((prev) => ({
                        ...prev,
                        loading: false,
                        error: 'Duplicate column names not allowed',
                    }));
                    return;
                }
                metadata.map(({ name, type }) => ({
                    name,
                    type,
                    description: nameToDescription?.get(name) ?? '',
                }));
                updateActiveDataset({
                    columns: metadata.map(({ name, type }) => ({
                        name,
                        type,
                        description: nameToDescription?.get(name) ?? '',
                    })),
                });
                setShowColumnsResponse((prev) => ({
                    ...prev,
                    loading: false,
                    error: undefined,
                }));
            },
            onError: (error) => {
                setShowColumnsResponse((prev) => ({ ...prev, loading: false, error }));
            },
            onBusy: (busy) => {
                setShowColumnsResponse((prev) => ({
                    ...prev,
                    loading: busy,
                    error: undefined,
                }));
            },
        },
        [selectedDatasetIndex, datasetSchema]
    );

    const [showColumnsResponse, setShowColumnsResponse] = useState<ColumnMedataResponse>({
        loading: false,
        error: undefined,
    });

    const onQueryChanged = useCallback(
        (text) => {
            updateActiveDataset({
                definitionQuery: text,
            });
        },
        [selectedDatasetIndex]
    );

    useEffect(() => {
        if (activeDataset?.columns) {
            if (activeDataset?.incrementalColumn) {
                setIsIncrementalColumnInvalid(
                    !activeDataset.columns.find((it) => it.name === activeDataset.incrementalColumn)
                );
            } else {
                setIsIncrementalColumnInvalid(false);
            }
        }
    }, [activeDataset?.columns, activeDataset?.incrementalColumn]);

    return (
        <div>
            <Typography variant={'h3'}>Define datasets</Typography>
            <div className={classes.helperText}>
                Your data product is made up of one or more datasets, based on queries you define.
                Enter the information below to define your dataset.
            </div>
            <ErrorIndicator text={error} />

            <Tabs
                className={tabClasses.tabs}
                indicatorColor="primary"
                textColor="primary"
                value={selectedDatasetIndex}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="datasets-tabs"
                onChange={(_, newValue): void => setSelectedDatasetIndex(newValue)}>
                {dataProductForm.datasets.map((dataset, index) => (
                    <DefaultTab
                        key={index}
                        label={
                            <span
                                className={clsx({
                                    [tabClasses.labelError]: duplicateDatasetNames.includes(
                                        dataset.name
                                    ),
                                })}>
                                {dataset.name}
                            </span>
                        }
                    />
                ))}
            </Tabs>
            {/* TODO: Add plus button for adding a new tab */}

            <Grid container mt={2}>
                <Grid item container xs={12}>
                    <Grid item container xs={12}>
                        <Typography variant="h5">Create dataset</Typography>
                    </Grid>
                    <Grid item container xs={12} md={5}>
                        <DatasetNameInput
                            value={activeDataset?.name || ''}
                            onChange={(name) =>
                                updateActiveDataset({
                                    name,
                                })
                            }
                            namesAlreadyUsed={namesWhichCanNotBeUsedForActiveDataset}
                            catalogName={dataProductForm.catalogName}
                            schemaName={dataProductForm.schemaName}
                            className={classes.textField}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        value={activeDataset?.description || ''}
                        label="Dataset description"
                        onChange={(e) =>
                            updateActiveDataset({
                                description: e.target.value,
                            })
                        }
                        className={classes.textArea}
                        variant="outlined"
                        margin="dense"
                        multiline={true}
                        minRows={5}
                        maxRows={30}
                        inputProps={{ maxLength: maximumFreeTextLength }}
                    />
                    <ErrorIndicator text={showColumnsResponse.error} />

                    <div className={classes.helperText}>
                        You must show columns before you can save and continue.
                    </div>
                    <QueryEditorWithDialog
                        key={selectedDatasetIndex}
                        label="Create dataset from query"
                        inputElementClass={classes.textArea}
                        onQueryChanged={onQueryChanged}
                        query={activeDataset?.definitionQuery}
                        required={true}
                    />

                    <Button
                        variant="text"
                        color="primary"
                        className={classes.fetchButton}
                        disabled={!activeDataset?.definitionQuery || showColumnsResponse.loading}
                        onClick={() => {
                            if (activeDataset?.definitionQuery && !showColumnsResponse.loading) {
                                setShowColumnsResponse((prev) => ({
                                    ...prev,
                                    loading: true,
                                }));
                                columnMetadataTrinoClient.execute(
                                    activeDataset.definitionQuery,
                                    'dataproduct'
                                );
                            }
                        }}>
                        <FontAwesomeIcon icon={faPlus} className={classes.addIcon} />
                        Show columns and add column descriptions
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    {isMaterializedViewEnabled && (
                        <DatasetTypeSection
                            dataset={activeDataset || createBrandNewEmptyDataset()}
                            isRefreshIntervalInvalid={isRefreshIntervalInvalid}
                            isIncrementalColumnInvalid={isIncrementalColumnInvalid}
                            onDatasetTypeChange={(value) => {
                                updateActiveDataset({
                                    type: value,
                                });
                            }}
                            onRefreshIntervalChange={(value) => {
                                updateActiveDataset({
                                    refreshInterval: value,
                                });
                                setIsRefreshIntervalInvalid(
                                    isNaN(Number(value)) || Number(value) < 60
                                );
                            }}
                            onIncrementalColumnChange={(value) => {
                                updateActiveDataset({
                                    incrementalColumn: value,
                                });
                            }}
                        />
                    )}
                </Grid>
            </Grid>

            {showColumnsResponse.loading ? (
                <Spinner position="relative" />
            ) : (
                !showColumnsResponse.error &&
                datasetSchema &&
                datasetSchema.length > 0 && (
                    <div>
                        <DataProductDataset
                            datasetName={activeDataset?.name}
                            columns={datasetSchema}
                            updateColumnDescription={updateColumnDescription}
                        />
                        <PreviewDataset sqlQuery={activeDataset.definitionQuery} />
                    </div>
                )
            )}

            <div className={classes.navBarSpacer} />

            <DataProductWizardNavigation
                dataProductForm={dataProductForm}
                tags={tags}
                wizardStep={2}
                publishFormDispatch={publishFormDispatch}
                setWizardStep={setWizardStep}
                setError={setError}
                addNewDataset={addNewDataset}
                mode={dataProductForm.id ? 'edit' : 'create'}
                saveDisabled={
                    isDuplicateDatasetName ||
                    (activeDataset?.type === DatasetType.MATERIALIZED_VIEW &&
                        isRefreshIntervalInvalid &&
                        isIncrementalColumnInvalid)
                }
            />
        </div>
    );
};

function createBrandNewEmptyDataset(): Dataset {
    return {
        type: DatasetType.VIEW,
        name: '',
        definitionQuery: '',
        description: '',
        columns: [],
    };
}

interface ColumnMedataResponse {
    loading: boolean;
    error: string | undefined;
}
