/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import React, { useContext, useMemo } from 'react';
import { StarburstQueryEditor, StarburstQueryEditorConfig } from '@starburstdata/query-editor';
import { QueryDetailsButton } from './QueryDetailsButton';
import { ConfigContext, uiFeatureEnabled } from '../../app/ConfigContextProvider';
import { baseUrl } from '../../api/api';
import { UiFeatures } from '../../api/configApi';
import { RoleContext } from '../biac/CurrentRoleContext';
import { createUseStyles } from 'react-jss';
import { QueryAccelerationLink } from './QueryAccelerationLink';
import { AddToDataProductFromQueryLink } from '../dataproduct/components/AddToDataProductFromQueryLink';
import { UIThemeContext, useThemeMode } from '../../app/UIThemeContextProvider';

const useStyles = createUseStyles({
    root: {
        margin: '8px 0 0 0',
        height: `calc(100vh - 56px - 16px)`,
    },
});

export const monacoWebWorkerPath = '/ui/insights/static/monaco';
export const languageElementsUrl = `${baseUrl}/ide/language`;
export const statementSubmissionUrl = `${baseUrl}/ide/statement`;
export const worksheetsPersistenceUrl = `${baseUrl}/openWorksheets`;

export const QueryEditor: React.FunctionComponent = () => {
    const configContext = useContext(ConfigContext);
    const classes = useStyles();
    const persistenceEnabled = configContext?.persistenceEnabled;
    const user = configContext?.user;
    const dataProductEnabled = configContext?.dataProductEnabled;
    const queries = uiFeatureEnabled(UiFeatures.QUERIES);
    const role = useContext(RoleContext);
    const themeMode = useThemeMode();
    const troubleshootingBaseUri = configContext?.troubleshootingBaseUri;
    const queryResultsDownloadEnabled = configContext?.queryResultsDownloadEnabled;
    const queryProxyEnabled = configContext?.queryProxyEnabled;
    const customThemeConfig = useContext(UIThemeContext).customThemeConfig;

    const headers = useMemo(() => {
        if (role !== 'disabled') {
            return {
                role: role.roleHeader,
            };
        } else {
            return {};
        }
    }, [role]);

    const roleId = useMemo(() => {
        if (role !== 'disabled') {
            if (role.currentRole === 'ALL') {
                return 'ALL';
            } else {
                return `${role.currentRole.id}`;
            }
        } else {
            return undefined;
        }
    }, [role]);

    const config: StarburstQueryEditorConfig = useMemo(() => {
        return {
            queryDetailsButton: queries ? QueryDetailsButton : null,
            queryAccelerationLink: queries ? QueryAccelerationLink : null,
            enableLakeAccelerationInfo: true,
            createDataProductButton: dataProductEnabled ? AddToDataProductFromQueryLink : null,
            source: {
                explorer: 'insights-explorer',
                editor: 'insights',
            },
            api: {
                statementSubmission: {
                    url: statementSubmissionUrl,
                    headers,
                },
                languageElements: languageElementsUrl,
                worksheetsPersistence: persistenceEnabled ? worksheetsPersistenceUrl : undefined,
            },
            staticFiles: {
                monaco: monacoWebWorkerPath,
            },
            queryTroubleshootingLink: troubleshootingBaseUri
                ? `${troubleshootingBaseUri}?selectedRole=${encodeURI(
                      headers.role ? headers.role : ''
                  )}&queryId=`
                : undefined,
            enableQueryProxyOption: queryProxyEnabled,
            className: classes.root,
            userId: user ?? '',
            roleId,
            themeMode: themeMode,
            queryResultsDownloadEnabled,
            customThemeConfig,
        };
    }, [queries, headers, roleId, themeMode, troubleshootingBaseUri, customThemeConfig]);

    return <StarburstQueryEditor config={config} />;
};
