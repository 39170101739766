/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import axios from 'axios';
import { baseUrl } from './api';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { persistActiveTabId } from '@starburstdata/query-editor';

interface CreateWorksheetRequest {
    id: string;
    name: string;
    queryText: string;
    tabNumber: number;
}

export const createWorksheet = (
    request: CreateWorksheetRequest,
    controller: AbortController
): Promise<unknown> => {
    return axios
        .post<unknown>(`${baseUrl}/worksheets`, request, {
            signal: controller.signal,
        })
        .then((response) => response.data);
};

export const useCreateWorksheet = () => {
    const history = useHistory();
    const [isPending, setPending] = useState(false);
    const abortController = useRef<AbortController | undefined>(undefined);

    useEffect(() => {
        return () => {
            abortController.current?.abort();
        };
    }, []);

    const createAndOpen = useCallback(
        async (worksheet: CreateWorksheetRequest, onError: (e: any) => void) => {
            try {
                const localAbortController = new AbortController();
                setPending(true);
                abortController.current = localAbortController;

                await createWorksheet(worksheet, localAbortController);
                persistActiveTabId(worksheet.id);
                history.push('/ide');
            } catch (e) {
                if (!axios.isCancel(e)) {
                    onError(e);
                    setPending(false);
                }
            }
        },
        [abortController, history]
    );

    return { isPending, createAndOpen };
};
