/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { faChevronDown, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createUseStyles } from 'react-jss';
import React, { useState, ReactElement } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';

interface ToggleProps {
    id: string;
    label: string | ((expanded: boolean) => string);
    children: ReactElement<HTMLElement>;
    summary?: (expanded: boolean) => ReactElement<HTMLElement> | null;
    className?: string;
}

const useStyles = createUseStyles((theme: Theme) => ({
    icon: {
        width: '16px!important',
        height: '14px!important',
        marginRight: '0.5rem',
    },
    showMore: {
        fontSize: '0.875rem',
        lineHeight: '1rem',
        letterSpacing: '1.25px',
        color: theme.palette.primary.main,
        fontWeight: 600,
        whiteSpace: 'nowrap',
        '&:hover': {
            textDecoration: 'underline',
            cursor: 'pointer',
        },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        userSelect: 'none',
    },
    content: {
        marginTop: '0.5rem',
    },
    summary: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'flex-end',
        flexWrap: 'wrap',
    },
}));

export const Toggle: React.FunctionComponent<ToggleProps> = ({
    children,
    label,
    className = '',
    summary,
}) => {
    const [enabled, setEnabled] = useState<boolean>(false);
    const classes = useStyles();

    const toggle = (): void => {
        setEnabled((prevState) => !prevState);
    };

    return (
        <>
            <Grid container className={className}>
                <Grid item xs={3} className={classes.showMore} onClick={toggle}>
                    <div>
                        <FontAwesomeIcon
                            className={classes.icon}
                            icon={enabled ? faChevronDown : faChevronRight}
                        />
                        {typeof label === 'string' ? label : label(enabled)}
                    </div>
                </Grid>

                <Grid item xs={9} className={classes.summary}>
                    {summary && summary(enabled)}
                </Grid>
            </Grid>

            <Box display={enabled ? 'block' : 'none'}>{children}</Box>
        </>
    );
};
