/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import axios from 'axios';

export interface CustomThemeConfig {
    main: string;
    link: string;
    info?: string;
    warning?: string;
    error?: string;
    menuItemHover: string;
    menuItemSelected: string;
    inActiveText: string;
    activeText: string;
    font: string;
    companyName: string;
    tooltip: string;
    aboutDialog?: {
        title?: string;
        aboutText?: string;
        version?: string;
    };
    welcomeBannerBackground: string;
}

export const getCustomTheme = (): Promise<CustomThemeConfig | undefined> => {
    return axios.get<CustomThemeConfig>(`/ui/theme/config`).then((response) => response.data);
};
