/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { ReactNode, useEffect, useState, useContext } from 'react';
import { createUseStyles } from 'react-jss';
import Grid from '@mui/material/Grid';
import InfoIcon from '@mui/icons-material/Info';
import { SimpleDialog } from '../../components/dialog/SimpleDialog';
import Skeleton from '@mui/material/Skeleton';
import MenuItem from '@mui/material/MenuItem';
import { ClusterInfo, getClusterInfo } from '../../api/configApi';
import { useFetchingState } from '../../features/dataproduct/domain/useFetchingState';
import { fromPromise } from 'rxjs/internal-compatibility';
import { UIThemeContext } from '../../app/UIThemeContextProvider';
import { MarkDown } from '../../components/markDown/MarkDown';

const useStyles = createUseStyles(() => ({
    menuItemIcon: {
        fontSize: '20px',
        marginRight: '16px',
    },
}));

export const AboutMenuItem: React.FunctionComponent = () => {
    const classes = useStyles();
    const [showAboutDialog, setShowAboutDialog] = useState(false);

    return (
        <>
            <AboutDialog
                isOpen={showAboutDialog}
                closeDialog={() => {
                    setShowAboutDialog(false);
                }}
            />
            <MenuItem
                onClick={() => {
                    setShowAboutDialog(true);
                }}>
                <InfoIcon className={classes.menuItemIcon} />
                About
            </MenuItem>
        </>
    );
};

const useDialogStyles = createUseStyles(() => ({
    viewport: {
        maxHeight: '25rem',
        overflow: 'auto',
        whiteSpace: 'nowrap',
    },
}));

interface ContentProps {
    clusterInfo: ClusterInfo | undefined;
    loading: boolean;
}

const DialogContent: React.FunctionComponent<ContentProps> = ({ clusterInfo, loading }) => {
    const classes = useDialogStyles();

    const row = (title: string, value: ReactNode): React.ReactNode => {
        return (
            <Grid container item xs={12} columnSpacing={1}>
                <Grid item xs={4}>
                    {title}
                </Grid>
                <Grid item xs={8}>
                    {loading ? <Skeleton variant="text" /> : value}
                </Grid>
            </Grid>
        );
    };

    const aboutDialogConfig = useContext(UIThemeContext).customThemeConfig?.aboutDialog;
    const aboutText = aboutDialogConfig?.aboutText;
    return (
        <Grid container wrap="nowrap" direction="column" rowSpacing={1}>
            {aboutText && (
                <Grid item xs={12}>
                    <div className={classes.viewport}>
                        <MarkDown text={aboutText} />
                    </div>
                </Grid>
            )}
            {row('Version', aboutDialogConfig?.version ?? clusterInfo?.nodeVersion.version ?? '-')}
            {row('JDK version', clusterInfo?.javaVersion ?? '-')}
            {row('Environment', clusterInfo?.environment ?? '-')}
        </Grid>
    );
};

const AboutDialog: React.FunctionComponent<{ isOpen: boolean; closeDialog: () => void }> = ({
    isOpen,
    closeDialog,
}) => {
    const state = useFetchingState<ClusterInfo>();

    useEffect(() => {
        if (isOpen) {
            state.setFetching();
            const subscription = fromPromise(getClusterInfo()).subscribe(
                state.setData,
                ({ message }) => state.setError(message)
            );
            return () => subscription.unsubscribe();
        }
    }, [isOpen]);

    const aboutDialogConfig = useContext(UIThemeContext).customThemeConfig?.aboutDialog;

    return (
        <SimpleDialog<ContentProps>
            title={aboutDialogConfig?.title ?? 'About'}
            Content={DialogContent}
            contentProps={{
                clusterInfo: state.data,
                loading: state.isFetching,
            }}
            isOpen={isOpen}
            close={closeDialog}
            cancelButtonLabel={'Close'}
        />
    );
};
