/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { navigateBackInHistoryParam } from '../query/details/QueryDetails';
import { createUseStyles } from 'react-jss';
import { Theme } from '@mui/material/styles';
import { paletteSwitch } from '../../themes/palette';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';

interface QueryDetailsLinkProps {
    queryId?: string;
    queryDone: boolean;
}

const useStyles = createUseStyles((theme: Theme) => ({
    button: {
        width: '30px',
        height: '30px',
        '&:hover': {
            backgroundColor: paletteSwitch(theme).black04,
        },
    },
    icon: {
        fontSize: '20px',
    },
}));

export const QueryDetailsButton: React.FunctionComponent<QueryDetailsLinkProps> = ({
    queryDone,
    queryId,
}) => {
    const history = useHistory();
    const classes = useStyles();

    const onClick = useCallback(() => {
        if (queryId) {
            history.push(`/query/${queryId}`, { [navigateBackInHistoryParam]: true });
        }
    }, [history, queryId]);

    return (
        <IconButton
            color="secondary"
            className={classes.button}
            disabled={!queryDone || !queryId}
            onClick={onClick}>
            <VisibilityIcon className={classes.icon} />
        </IconButton>
    );
};
