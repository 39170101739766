/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import React, { useContext } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { FontLoader } from './FontLoader';
import { createGenerateId, JssProvider, ThemeProvider as JssThemeProvider } from 'react-jss';
import { UIThemeContext } from './UIThemeContextProvider';

interface ThemingProps {
    children: React.ReactElement;
}
const generateId = createGenerateId();
export const Theming: React.FunctionComponent<ThemingProps> = ({ children }) => {
    const theme = useContext(UIThemeContext).theme;
    return (
        <StyledEngineProvider injectFirst>
            <JssProvider generateId={generateId}>
                <MuiThemeProvider theme={theme}>
                    <JssThemeProvider theme={theme}>
                        <CssBaseline enableColorScheme />
                        <FontLoader>{children}</FontLoader>
                    </JssThemeProvider>
                </MuiThemeProvider>
            </JssProvider>
        </StyledEngineProvider>
    );
};
