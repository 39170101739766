/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useMemo, useContext } from 'react';

import { EnterpriseLogo } from '../../components/logos/EnterpriseLogo';
import { useStyles } from '../../themes/styles';
import { createUseStyles } from 'react-jss';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import logoutBackground from '../../images/enterprise-signout.jpg';
import { UIThemeContext } from '../../app/UIThemeContextProvider';
import { Link } from 'react-router-dom';
import { palette } from '../../themes/palette';

const useLocalStyles = createUseStyles({
    root: {
        backgroundImage: `url(${logoutBackground})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    content: {
        width: '100%',
    },
    logoContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttonContainer: {
        marginTop: '36px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttonLink: {
        textDecorationColor: palette.white,
    },
});

export const LogoutPage: React.FunctionComponent = () => {
    const localClasses = useLocalStyles();
    const classes = useStyles();
    const customThemeConfig = useContext(UIThemeContext).customThemeConfig;
    const customThemeRootComponentOverrides = useMemo(() => {
        if (customThemeConfig?.main) {
            return {
                backgroundImage: 'unset',
                backgroundColor: customThemeConfig.main,
            };
        }
        return undefined;
    }, [customThemeConfig]);

    return (
        <Box className={localClasses.root} style={customThemeRootComponentOverrides}>
            <Grid container spacing={3} className={localClasses.content}>
                {!customThemeConfig && (
                    <Grid item xs={12} className={localClasses.logoContainer}>
                        <div>
                            <EnterpriseLogo />
                        </div>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Container maxWidth="sm">
                        <Typography variant="h4" component="h4" className={classes.header}>
                            You are now logged out.
                        </Typography>
                    </Container>
                </Grid>
                <Grid item xs={12} className={localClasses.buttonContainer}>
                    <Link
                        className={localClasses.buttonLink}
                        to={'#'}
                        onClick={() => {
                            // Browser redirect should happen for oauth instead of react-routing}
                            location.href = '/ui/insights/login';
                        }}>
                        <Typography variant="h4" component="h4" className={classes.header}>
                            Sign back in
                        </Typography>
                    </Link>
                </Grid>
            </Grid>
        </Box>
    );
};
