/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from 'react';
import { createUseStyles } from 'react-jss';
import { DataProductOwner } from '../../../api/dataProduct/dataProductApi';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import IconButton from '@mui/material/IconButton';
import { paletteSwitch } from '../../../themes/palette';
import { Theme } from '@mui/material/styles';

interface OwnerDetailsProps {
    productOwner: DataProductOwner;
}

const useStyles = createUseStyles((theme: Theme) => ({
    title: {
        fontSize: '0.875rem',
        paddingTop: '0.5rem',
        wordBreak: 'break-word',
    },
    email: {
        wordBreak: 'break-word',
        fontSize: '0.875rem',
        color: theme.palette.primary.main,
        textDecoration: 'none',
    },
}));

export const OwnerDetails: React.FunctionComponent<OwnerDetailsProps> = ({ productOwner }) => {
    const classes = useStyles();

    return (
        <div>
            <div className={classes.title}>{productOwner.name}</div>
            <div className={classes.email}>{productOwner.email}</div>
        </div>
    );
};

const useEditableOwnerDetailsClasses = createUseStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    deleteButton: {
        color: paletteSwitch(theme).error,
    },
}));

interface EditableOwnerDetailsProps extends OwnerDetailsProps {
    onDeleteClick: () => void;
}

export const EditableOwnerDetails = ({
    onDeleteClick,
    productOwner,
}: EditableOwnerDetailsProps) => {
    const internalClasses = useEditableOwnerDetailsClasses();
    return (
        <div className={internalClasses.root}>
            <OwnerDetails productOwner={productOwner} />
            <IconButton
                className={internalClasses.deleteButton}
                size="small"
                onClick={onDeleteClick}>
                <DeleteOutline />
            </IconButton>
        </div>
    );
};
