/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import { createUseStyles } from 'react-jss';
import { paletteSwitch } from '../../themes/palette';
import { ReportContainer } from './ReportContainer';
import { Theme } from '@mui/material/styles';

const useStyles = createUseStyles((theme: Theme) => ({
    multilineRoot: {
        padding: '12px 16px',
    },
    root: {
        padding: '6px 16px 12px 12px',
    },
    tab: {
        cursor: 'pointer',
        userSelect: 'none',
        fontWeight: 600,
        marginRight: '4px',
        '&:hover': {
            backgroundColor: paletteSwitch(theme).black2,
            borderRadius: '4px 4px 0 0',
        },
    },
    selectedTab: {
        borderBottom: `3px solid ${theme.palette.primary.main}`,
    },
    unselectedTab: {
        marginBottom: '3px',
        color: theme.palette.primary.main,
    },
    smallHeader: {
        fontSize: '0.875rem',
        fontWeight: 600,
    },
    header: {
        fontSize: '0.875rem',
        fontWeight: 600,
    },
    tabLine: {
        borderBottom: `1px solid ${paletteSwitch(theme).black12}`,
        fontSize: '0.875rem',
    },
}));

interface GroupedReportsProps<T extends string> {
    title: string | React.ReactNode;
    tabs: Array<{ tab: T; label: string }>;
    description?: string;
    children: (selectedTab: T) => React.ReactElement;
}

export const GroupedReports = <T extends string>({
    description = undefined,
    title,
    tabs,
    children,
}: GroupedReportsProps<T>): JSX.Element => {
    const classes = useStyles();
    const [tab, setTab] = useState<T>(tabs[0].tab);

    const getTab = (tabId: typeof tab, label: string) => {
        return (
            <Box
                key={tabId}
                p={1}
                pr={1}
                className={clsx(classes.tab, {
                    [classes.selectedTab]: tab === tabId,
                    [classes.unselectedTab]: tab !== tabId,
                })}
                onClick={() => setTab(tabId)}>
                {label}
            </Box>
        );
    };

    const getTabs = () => <>{tabs.map(({ label, tab }) => getTab(tab, label))}</>;

    const multiLineHeader = () => (
        <>
            <div className={classes.header}>{title}</div>
            <Box display="flex" alignItems="center" className={classes.tabLine}>
                <Box mr="auto">{description}</Box>
                {getTabs()}
            </Box>
        </>
    );

    const singleLineHeader = () => (
        <Box display="flex" alignItems="center" className={classes.tabLine}>
            <Box mr="auto" className={classes.smallHeader}>
                {title}
            </Box>
            {getTabs()}
        </Box>
    );

    return (
        <ReportContainer
            title={
                <Box style={{ width: '100%' }}>
                    {description !== undefined ? multiLineHeader() : singleLineHeader()}
                </Box>
            }
            className={clsx({
                [classes.root]: description === undefined,
                [classes.multilineRoot]: description !== undefined,
            })}>
            {children(tab)}
        </ReportContainer>
    );
};
