/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useContext, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import Grid from '@mui/material/Grid';
import { paletteSwitch } from '../../themes/palette';
import { QueriesOverview } from './QueriesOverview';
import { TopUsersOverview } from './TopUsersOverview';
import {
    getNodesOverview,
    getQueriesOverview,
    NodesOverviewResponse,
    QueriesOverviewResponse,
} from '../../api/overviewApi';
import { WorkersOverview } from './WorkersOverview';
import { parseISO } from 'date-fns';
import { ConfigContext } from '../../app/ConfigContextProvider';
import useMediaQuery from '@mui/material/useMediaQuery';
import { AlignedCharts } from '../../components/chart/AlignedCharts';
import clsx from 'clsx';
import { PageContent } from '../../layout/PageContent';
import { AuthorizedUserBiac } from '../../components/biac/AuthorizedUserBiac';
import { UiFeatures } from '../../api/configApi';
import { Theme } from '@mui/material/styles';

const useStyles = createUseStyles((theme: Theme) => ({
    differenceValue: {
        color: paletteSwitch(theme).errorDark,
        marginRight: '0.5rem',
    },
    wideCharts: {},
    fullWidthCharts: {
        width: '100%',
    },
    narrowCharts: {},
    chartsContainer: {},
    workersChart: {},
    '@media (max-width: 1350px)': {
        wideCharts: {
            width: '100%',
            marginTop: '16px',
        },
        narrowCharts: {
            marginTop: '16px',
            width: '100%',
        },
    },
    '@media (min-width: 1350px)': {
        chartsContainer: {
            display: 'flex',
        },
        wideCharts: {
            width: 'calc(100% - 450px - 16px)',
            marginRight: '16px',
        },
        narrowCharts: {
            width: '450px',
            minWidth: '450px',
        },
    },
    '@media (max-width: 1500px)': {
        workersChart: {
            width: '100%',
        },
    },
    '@media (min-width: 1500px)': {
        chartsContainer: {
            display: 'flex',
        },
        wideCharts: {
            width: 'calc(100% - 550px - 16px)',
            marginRight: '16px',
        },
        narrowCharts: {
            width: '550px',
            minWidth: '550px',
        },
    },
    '@media (min-width: 2000px)': {
        chartsContainer: {
            display: 'flex',
        },
        wideCharts: {
            width: 'calc(70% - 16px)',
            marginRight: '16px',
        },
        narrowCharts: {
            width: '30%',
            minWidth: '30%',
        },
    },
}));

const defaultPollingInterval = 30 * 1000;

interface NodesOverview {
    current: NodesOverviewResponse | 'loading' | 'error';
    previous: NodesOverviewResponse | undefined;
}

export interface OverviewChartsHeight {
    smallChart: number;
    bigChart: number;
    table: number;
}

export const Overview: React.FunctionComponent = () => {
    const classes = useStyles();
    const [queriesOverview, setQueriesOverview] = useState<
        QueriesOverviewResponse | 'loading' | 'error'
    >('loading');
    const [nodesOverview, setNodesOverview] = useState<NodesOverview>({
        current: 'loading',
        previous: undefined,
    });
    const topUsersChartsAvailable = useContext(ConfigContext)?.allQueries;
    const lgHeight = useMediaQuery('(min-height:1000px)');

    const height: OverviewChartsHeight = {
        smallChart: lgHeight ? 130 : 110,
        bigChart: lgHeight ? 280 : 240,
        table: lgHeight ? 307 : 260,
    };

    const loadQueriesOverview = async (): Promise<QueriesOverviewResponse | 'error'> => {
        let result: QueriesOverviewResponse | 'error';
        try {
            result = await getQueriesOverview();
        } catch {
            result = 'error';
        }
        setQueriesOverview(result);
        return result;
    };

    const loadNodesOverview = async (): Promise<NodesOverviewResponse | 'error'> => {
        try {
            const overview = await getNodesOverview();
            setNodesOverview((prevState) => ({
                current: overview,
                previous: typeof prevState.current === 'string' ? undefined : prevState.current,
            }));
            return overview;
        } catch {
            setNodesOverview({
                current: 'error',
                previous: undefined,
            });
            return 'error';
        }
    };

    const startPolling = async (
        pollingFn: () => Promise<{ samplingFrequency: number } | 'error'>
    ): Promise<ReturnType<typeof setTimeout>> => {
        const initialData = await pollingFn();
        const samplingFrequency =
            typeof initialData === 'string'
                ? defaultPollingInterval
                : initialData.samplingFrequency;

        return setInterval(() => pollingFn(), samplingFrequency);
    };

    useEffect(() => {
        const queriesIntervalHandle = startPolling(loadQueriesOverview);
        const nodesIntervalHandle = startPolling(loadNodesOverview);

        return (): void => {
            queriesIntervalHandle.then((handle) => clearInterval(handle));
            nodesIntervalHandle.then((handle) => clearInterval(handle));
        };
    }, []);

    const getProperty = <T extends Record<string, unknown>, K extends keyof T>(
        obj: T | 'loading' | 'error',
        property: K
    ): T[K] | 'loading' | 'error' => {
        if (typeof obj === 'string') {
            return obj;
        }

        return obj[property];
    };

    return (
        <AuthorizedUserBiac uiFeature={UiFeatures.OVERVIEW}>
            <PageContent title="Overview">
                <AlignedCharts>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div className={classes.chartsContainer}>
                                <div
                                    className={
                                        topUsersChartsAvailable
                                            ? classes.wideCharts
                                            : classes.fullWidthCharts
                                    }>
                                    <QueriesOverview
                                        queriesInTime={getProperty(queriesOverview, 'history')}
                                        cpuUsage={getProperty(
                                            nodesOverview.current,
                                            'cpuLoadHistory'
                                        )}
                                        height={height}
                                        narrowChartClass={classes.narrowCharts}
                                        wideChartClass={classes.wideCharts}
                                    />
                                </div>

                                {topUsersChartsAvailable && (
                                    <div className={classes.narrowCharts}>
                                        <TopUsersOverview
                                            queriesByUser={getProperty(
                                                queriesOverview,
                                                'queriesByUser'
                                            )}
                                            cpuTimeByUser={getProperty(
                                                queriesOverview,
                                                'cpuTimeByUser'
                                            )}
                                            oldestQueryTime={
                                                typeof queriesOverview === 'string'
                                                    ? undefined
                                                    : queriesOverview.oldestQueryTime
                                            }
                                            height={height}
                                        />
                                    </div>
                                )}
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <WorkersOverview
                                nodeSummaries={getProperty(nodesOverview.current, 'nodeSummaries')}
                                previousNodeSummaries={nodesOverview.previous?.nodeSummaries}
                                cpuUsage={getProperty(nodesOverview.current, 'cpuLoadHistory')}
                                lastScalingTime={parseISO(
                                    getProperty(nodesOverview.current, 'lastScalingTime')
                                ).toLocaleString()}
                                chartClassName={clsx(classes.wideCharts, {
                                    [classes.workersChart]: !topUsersChartsAvailable,
                                })}
                                summaryClassName={classes.narrowCharts}
                                height={height}
                            />
                        </Grid>
                    </Grid>
                </AlignedCharts>
            </PageContent>
        </AuthorizedUserBiac>
    );
};
